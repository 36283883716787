import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function locationandnumbers() {
    return (
        <>
            <Container>
                <div className="headerFrame">
                    <h1 style={{ color: 'white' }}>Location & Numbers</h1>
                </div>
                <Row>
                    <Col md={9}>
                        <iframe className="border" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d212.6981647951352!2d74.410239122808!3d31.464491909274532!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391908a1438cb8a3%3A0x486d9621745dfd3e!2sSPM%20Consulting!5e0!3m2!1sen!2s!4v1713248430907!5m2!1sen!2s" height="700" style={{ border: 0, width: '100%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Col>
                    <Col md={3}>
                        <div id="sidebar" className="text-center border">
                            <h3><span itemprop="name">SPM Consulting </span><br />Office Locations</h3>
                            <img src="c/images/stories/worldmap.png" alt="Office Locations"></img>
                            <div itemprop="address" itemscope="" itemtype="http://schema.org/PostalAddress">
                                <strong>Email:</strong> <a href="mailto:info@spmconsulting.net">info@spmconsulting.net</a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <br />
            <br />
        </>
    )
}

export default locationandnumbers;