import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink } from "react-router-dom";

function whyjoinspm() {
    return (
        <>
            <Container>
                <div className="headerFrame">
                    <h1 style={{ color: 'white' }}>Why Join SPM?</h1>
                </div>
                <p className="headerContent">
                    SPM always look for good resources who are enthusiastic and want to work in a
                    challenging environment. Candidates with strong business background or IT
                    background can apply in confidence at <a href="mailto:info@spmconsulting.net">info@spmconsulting.net</a>
                    to be included in our reference database for future opportunities.
                </p>
                <ul id="navlist">
                    <li><b>A Collaborative Environment to work in.</b> SPM provides an excellent platform
                        for talented individuals to work with our subject matter experts and global partners
                        to deliver results for our customers as well as enhance your skill set and expertise.</li>
                    <li><b>An unparalleled support team.</b> Through our internal resources, highly qualified
                        global partners and overall business connections, all employees at SPM feel comfortable
                        given the support they need.</li>
                    <li><b>Our Values.</b> Business Ethics and our values have been at the very heart of
                        SPM ever since its initiation. We cherish and celebrate the same with every customer
                        engagement.</li>
                    <li><b>Diversity.</b> SPM provides you with a unique opportunity to work in a diversified
                        environment. The team members, colleagues, vendors, clients and business partners
                        come from a diverse background, allowing all employees to interact with diverse
                        groups of individuals at the global level.</li>
                    <li><b>Balancing your work life.</b> We at SPM strongly believe in optimizing your time
                        at work and with family. Although work at SPM can get intense, but due to our collaborative
                        working environment, unparalleled support and flexible work routine, our employees
                        manage to find just the right balance. Furthermore, the company offers part-time,
                        home-based and flexible working hours models as well.</li>
                    <li><b>Delivering value for our customers.</b> All members of SPM have experienced the
                        feeling of accomplishment, when our clients realize and stalk of the value addition
                        SPM has created for their business operations and profitability.</li>
                    <li><b>Global Experience</b> and the opportunity to interact with people of all races,
                        religions, backgrounds and geographies.</li>
                    <li><b>Quick decision making and active management</b></li>
                    <li><b>Put your ideas into practice.</b> Present your business plan / feasibility plan
                        and bring the same into practice upon convincing the team.</li>
                    <li><b>Specialization in Risk Management.</b> SPM’s core expertise is in the Risk Analytics
                        space, allowing for specialization in the same as well as a great reputation in
                        the global market</li>
                    <li><b>Work with the top tier Global Financial and Non-Financial institutions.</b> SPM
                        is currently engaged in multiple projects, assisting our clients in consulting and
                        technology implementations through our highly skilled business, solution and technical
                        resources.</li>
                    <li><b>Flexibility.</b> Due to the nature of the Business Solutions industry, SPM works
                        on a stable, yet flexible business model. This allows the organization to expand
                        into new areas and grow along with the market and our customers’ requirements.</li>
                    <li><b>SPM is an Equal Employment Opportunity Firm.</b></li>
                </ul>
            </Container>
            <br />
            <br />
        </>
    )
}

export default whyjoinspm;