import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink } from "react-router-dom";

function missionandvision() {
    return (
        <>
            <Container>
                <div className="headerFrame">
                    <h1 style={{ color: 'white' }}>Mission &amp; Vision</h1>
                </div>
                <p className="headerContent">
                    “At SPM Consulting, we are dedicated to providing the highest standards of consultancy
                    services; delivering value and excellence. It is with this vigor that we work meticulously
                    to understand the requirements of our clients and establish perfect harmony between
                    solution implementation and business consultancy. Our firm lives by the canon of
                    cultivating long term relationships, based on trust and integrity, with its most
                    treasured assets – clients &amp; employees. At SPM Consulting, ethics and best business
                    practices are not just enforced, but celebrated! All of this, with the objective
                    of becoming the most reliable and trusted business and solution consultancy firm,
                    globally.”
                    <br />
                    Company Objectives: The company’s core aim has always been to work closely
                    with its clients to provide consulting services not just at the solution level,
                    but more importantly at the organizational and business process level ensuring application
                    performance, optimal decision making and maximize return on investment. SPM Consulting
                    has developed a fine-tuned and successful synergy between innovative risk management
                    strategies and software solutions. The company has a very meticulous approach to
                    analyze the existing framework and system, identify gaps and implement the SAS Solutions
                    in accordance to the client’s needs and best business practices. It involves a detailed
                    examination of the proposed system implementation, gap identification, issue resolution
                    and advisory services on business framework development.
                    <br />
                    With the determination
                    and vigor to work towards achieving the above mentioned objectives, SPM Consulting
                    has worked closely with its clients to deliver projects which enable them to identify,
                    monitor and analyze data to facilitate the decision making process. Our objective
                    is to cultivate close relationship with our clients, reassuring them that our consultants
                    have the client’s best interest in mind and we benefit from ‘reputational capital’
                    such integrity generates.
                </p>
            </Container>
            <br />
            <br />
        </>
    )
}

export default missionandvision;