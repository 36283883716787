import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './Views/header';
import Index from './Views/index';
import Missionandvision from './Views/missionandvision'
import Spmoutsourcingservices from './Views/spmoutsourcingservices'
import Whyjoinspm from './Views/whyjoinspm'
import Locationandnumbers from './Views/locationandnumbers'
import Footer from './Views/footer';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Index />} />
        <Route path='*' element={<Index />} />
        <Route path='/mission-and-vision/' element={<Missionandvision />} />
        <Route path='/spm-outsourcing-services/' element={<Spmoutsourcingservices />} />
        <Route path='/why-join-spm/' element={<Whyjoinspm />} />
        <Route path='/location-and-numbers/' element={<Locationandnumbers />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
