import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";

function index() {
    window.history.pushState({}, null, '/');
    return (
        <>
            <Container fluid>
                <Row>
                    <RenderCarousel />
                </Row>
                <br />
                <br />
                <Container>
                    <Row>
                        <Col md={8}>
                            <h2>GRC Services</h2>
                            <p>SPM’s Center of Excellence in Governance, Risk & Compliance provide management consultancy based on Basel-II,
                                Best Practices and latest frameworks augmented with Solution Consultancy for the state-of-the-art GRC Solutions
                                including IBM® OpenPages & SAS® eGRC. From Management Consultancy to Solution Consultancy, our consultants have
                                the knowledge and experience to deliver the best! SPM provides Following High Level GRC Services:</p>
                            <ul id="list">
                                <li><NavLink>Framework Development & Management Consulting</NavLink></li>
                                <li><NavLink>GRC Solution Implementation & Migration Services</NavLink></li>
                                <li><NavLink>OpRisk AMA Roadmap</NavLink></li>
                                <li><NavLink>Training, Communication & Education</NavLink></li>
                                <li><NavLink>Continuous Improvement & Support</NavLink></li>
                            </ul>
                        </Col>
                        <Col md={4}>
                            <h3>Featured Clients</h3>
                            <Carousel controls={false} indicators={false} fade={true}>
                                <Carousel.Item>
                                    <img className="d-block w-100" style={{ height: '270px' }} src='../images/a1.PNG' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block w-100" style={{ height: '270px' }} src='../images/b2.PNG' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block w-100" style={{ height: '270px' }} src='../images/c3.PNG' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block w-100" style={{ height: '270px' }} src='../images/d4.PNG' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block w-100" style={{ height: '270px' }} src='../images/e5.PNG' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block w-100" style={{ height: '270px' }} src='../images/f6.PNG' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block w-100" style={{ height: '270px' }} src='../images/g7.PNG' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block w-100" style={{ height: '270px' }} src='../images/h8.PNG' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block w-100" style={{ height: '270px' }} src='../images/i9.PNG' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block w-100" style={{ height: '270px' }} src='../images/j10.PNG' />
                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className="d-block w-100" style={{ height: '270px' }} src='../images/k11.PNG' />
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <hr />
                    <Row>
                        <Col>
                            <h3>Enhance Your Risk Management with Our OpenPages Services</h3>
                            <p>At SPMConsulting, we specialize in providing tailored solutions to optimize your risk management processes and ensure compliance with regulatory requirements. Our team of experts has extensive experience in implementing and customizing OpenPages to suit your unique business needs.</p>
                            <p>Here's how we can help you:</p>
                            <h4>Implementation and Configuration</h4>
                            <p>We'll work closely with your team to seamlessly integrate OpenPages into your existing systems and customize it to align with your risk management framework.</p>
                            <h4>Training and Support</h4>
                            <p>Our training programs ensure that your staff are equipped with the knowledge and skills to maximize the potential of OpenPages. Additionally, our dedicated support team is always available to address any queries or issues you may encounter.</p>
                            <h4>Enhancements and Upgrades</h4>
                            <p>As OpenPages evolves, we'll ensure that your system remains up-to-date with the latest features and enhancements, keeping you ahead of regulatory changes and industry trends.</p>
                            <h4>Risk Assessment and Analysis:</h4>
                            <p>Leveraging the powerful capabilities of OpenPages, we'll assist you in conducting comprehensive risk assessments and analyzing data to identify potential threats and opportunities.</p>
                            <h4>Reporting and Dashboards</h4>
                            <p>
                                Our custom reporting and dashboard solutions provide you with actionable insights into your organization's risk profile, enabling informed decision-making at every level.
                                <br />
                                Partnering with your company for your OpenPages needs means gaining a trusted ally dedicated to helping you achieve your risk management objectives efficiently and effectively.
                                <br />
                                For further details , please write to us at <b>openpages@spmconsulting.net</b>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <br />
            <br />
        </>
    )
}

function RenderCarousel() {
    return (
        <Carousel id="mainCarousel" data-bs-theme="dark" controls={true} indicators={false} className="w-100" fade={true}>
            <Carousel.Item>
                <img className="d-block w-100 h-50" src='../images/a.jpg' alt="SPM – A Global Consultancy Firm" />
                <Carousel.Caption>
                    <h3>SPM – A Global Consultancy Firm Specializes In Governance, Risk &amp; Compliance</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100 h-50" src='../images/c.jpg' alt="Accelerate your Software Velocity" />
                <Carousel.Caption>
                    <h3>Business Partners Of IBM ® OpenPages</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100 h-50" src='../images/header1.jpg' alt="SPM – A Global Consultancy Firm" />
                <Carousel.Caption>
                    <h3>With Over 20 Successful GRC Solution Implementations Worldwide, SPM has Positioned Itself as One of the Best GRC Consultants among Its Peers.</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100 h-50" src='../images/header2.gif' alt="Accelerate your Software Velocity" />
                <Carousel.Caption>
                    <h3>GRC Services Include Gap Analysis, Framework Consulting, Training &amp; Development, Solution Implementation, OpVar, AMA Capital Modeling.</h3>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100 h-50" src='../images/header3.jpg' alt="Accelerate your Software Velocity" />
                <Carousel.Caption>
                    <h3>
                        Whether You Need Help And Guidance At The Board, C-Level, Or Enterprise Risk And
                        Compliance Level, Our Professionals Have Diversified Experience In Major Industries,
                        So We Can Provide Guidance And Insight That Makes Sense For Your Unique Business.
                    </h3>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default index;