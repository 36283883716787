import React from "react";
import { NavLink } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

function header() {
    function closeNav() {
        if (document.getElementById("basic-navbar-nav") != null) {
            document.getElementById("basic-navbar-nav").classList.remove("show");
        }
    }

    return (
        <>
            <Container fluid style={{ backgroundColor: '#13223C', paddingTop: '5px', paddingBottom: '5px' }}>
                <Container>
                    <Row>
                        <Col md={9} xs={3} className='my-auto'><NavLink to='/' className='text-white' style={{ fontSize: '70%' }}>Home</NavLink></Col>
                        <Col md={2} xs={5} className='my-auto'><small className='text-white float-right' style={{ fontSize: '70%' }}>Email: info@spmconsulting.net</small></Col>
                        <Col md={1} xs={4} className='my-auto'><NavLink to='/why-join-spm/' className='float-right text-white' style={{ fontSize: '70%' }}>Careers</NavLink></Col>
                    </Row>
                </Container>
            </Container>
            <Container>
                <Row className="justify-content-md-around">
                    <Col md={2} xs={9}><NavLink to='/'><img src='../images/spmlogo.png' style={{ width: '100%', height: '65px' }} /></NavLink></Col>
                    <Col md={8} xs={3}>
                        <Navbar expand="lg" className="bg-body-tertiary mx-auto mt-1">
                            <Container>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-md-end">
                                    <Nav>
                                        <NavLink className='mb-4 mt-2 d-md-none d-block'><img src='../images/spmlogo.png' style={{ width: '100%', height: 'auto' }} /></NavLink>
                                        <NavLink to="/mission-and-vision/" onClick={closeNav} className='text-md-center'><b className="text-dark">WHO WE ARE</b> <br /> <small style={{ fontSize: '11px', color: '#7191cb', fontWeight: '700' }}>mission and vision</small></NavLink>
                                        <NavLink to="/spm-outsourcing-services/" onClick={closeNav} className='text-md-center'><b className="text-dark">WHAT WE DO</b> <br /> <small style={{ fontSize: '11px', color: '#7191cb', fontWeight: '700' }}>our services</small></NavLink>
                                        <NavLink to="/why-join-spm/" onClick={closeNav} className='text-md-center'><b className="text-dark">CAREERS</b> <br /> <small style={{ fontSize: '11px', color: '#7191cb', fontWeight: '700' }}>work with us</small></NavLink>
                                        <NavLink to="/location-and-numbers/" onClick={closeNav} className='text-md-center'><b className="text-dark">CONTACT US</b> <br /> <small style={{ fontSize: '11px', color: '#7191cb', fontWeight: '700' }}>to learn more</small></NavLink>
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
            <div id="headershadow"></div>
        </>
    )
}

export default header;