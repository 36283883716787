import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function footer() {
    return (
        <>
            <Container fluid style={{ background: '#182947 url(../images/royal-blue/bg-footer.jpg) repeat-x' }}>
                <Container className="pt-3 pb-4" id="footer">
                    <Row className="justify-content-center footer-holder">
                        <Col md={3}>
                            <h6>AWARDS AND PARTNERSHIPS</h6>
                            <Row>
                                <Col xs={7}>
                                    <img src="../images/grc1.png" style={{ width: '100%' }} />
                                </Col>
                                <Col xs={5}>
                                    <img src="../images/ibm.png" style={{ width: '100%' }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <img src="../images/persontyle.png" style={{ width: '100%' }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={8}>
                                    <img src="../images/grct.png" style={{ width: '' }} />
                                </Col>
                                <Col xs={4}>
                                    <img src="../images/sas.png" style={{ width: '100%' }} />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3} className="mt-md-0 mt-3">
                            <h6>CONTACT INFORMATION</h6>
                            <a href="/location-and-numbers/">Contact Us Here</a> or Call Us:<br />
                            <a href="/location-and-numbers/">Whatsapp:</a>
                            <a href="tel:+923009881111" className="telclck">+92 300 9881111</a>
                            <br />
                            <a href="/location-and-numbers/">Address:</a>
                            1st Floor, Building
                            <br />
                            No. 6, Sector F, Commercial Area, <br /> Phase 1, DHA, Lahore Cantt
                            <br /> Pakistan 54000
                        </Col>
                        <Col md={3} className="mt-md-0 mt-3">
                            <h6>FOLLOW US</h6>
                            <ul className="social_icons">
                                <li><a href="https://www.facebook.com/SPMConsulting.net" target='_blank' className="facebook mb-2" onclick="window.open(this.href);return false;" style={{ opacity: 1 }}>Facebook</a></li>
                                <li><a href="https://www.linkedin.com/company/1634442?trk=tyah&amp;trkInfo=tarId%3A1412416853164%2Ctas%3ASPMConsul%2Cidx%3A1-1-1" target='_blank' className="linkedin" onclick="window.open(this.href);return false;" style={{ opacity: 1 }}>LinkedIn</a></li>
                            </ul>
                            <p>
                                <a title="Contact us" href="/location-and-numbers/" className="ka_button small_button small_fire" style={{ opacity: '0.7' }}>
                                    <b className="read_learn_more_icon"></b><span>Contact Us</span>
                                </a>
                            </p>
                        </Col>
                        <Col md={3} className="mt-md-0 mt-3">
                            <h6>GET INTOUCH WITH US</h6>
                            <div id="mc_signup">
                                Get in touch with us.. we would like to hear from you. Send us your enquiries
                                at <a href="mailto:info@spmconsulting.net">info@spmconsulting.net</a> and we shall
                                reply back asap.
                            </div>
                        </Col>
                        {/* <div id="footer">
                            <div className="footer-area">
                                <div className="footer-holder">
                                    <div className="module">
                                        <div>
                                            <div>
                                                <div>
                                                    <div className="custom">
                                                        <div className="one_fourth">
                                                            <h3>Awards and Partnerships</h3>
                                                            <div className="footer_post">
                                                                <div>
                                                                    <div className="partnerbox">
                                                                        <img src="../images/grc1.png" />
                                                                    </div>
                                                                    <div className="partnerbox" style={{ marginLeft: '15px', width: '70px' }}>
                                                                        <img src="../images/ibm.png" style={{ width: '100px', height: '60px', marginTop: '-10px' }} />
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <div className="partnerbox" style={{ marginTop: '-10px' }}>
                                                                        <img src="../images/persontyle.png" height="60px" width="width: 230px;" />
                                                                    </div>
                                                                </div>
                                                                <br />
                                                                <div>
                                                                    <div className="partnerbox" style={{ marginTop: '70px', marginLeft: '56px', width: '55px' }}>
                                                                        <img src="../images/sas.png" style={{ width: '80px' }} />
                                                                    </div>
                                                                    <div className="partnerbox" style={{ marginTop: '-65px', width: '85px' }}>
                                                                        <img src="../images/grct.png" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="module">
                                        <div>
                                            <div>
                                                <div>
                                                    <div className="custom">
                                                        <div className="one_fourth">
                                                            <h3>Contact Information</h3>
                                                            <a href="/location-and-numbers/">Contact Us Here</a> or Call Us:<br />
                                                            <ul className="blogroll">
                                                                <li>
                                                                    <a href="/location-and-numbers/">Whatsapp:</a>
                                                                    <a href="tel:+923009881111" className="telclck">+92 300 9881111</a>
                                                                    <br />
                                                                    <a href="/location-and-numbers/">Address:</a>
                                                                    1st Floor, Building
                                                                    <br />
                                                                    No. 6, Sector F, Commercial Area, <br /> Phase 1, DHA, Lahore Cantt
                                                                    <br /> Pakistan 54000
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="module">
                                        <div>
                                            <div>
                                                <div>
                                                    <div className="custom">
                                                        <div className="one_fourth">
                                                            <h3>Follow Us</h3>
                                                            <ul className="social_icons">
                                                                <li><a href="https://www.facebook.com/SPMConsulting.net" target='_blank' className="facebook mb-2" onclick="window.open(this.href);return false;" style={{ opacity: 1 }}>Facebook</a></li>
                                                                <li><a href="https://www.linkedin.com/company/1634442?trk=tyah&amp;trkInfo=tarId%3A1412416853164%2Ctas%3ASPMConsul%2Cidx%3A1-1-1" target='_blank' className="linkedin" onclick="window.open(this.href);return false;" style={{ opacity: 1 }}>LinkedIn</a></li>
                                                            </ul>
                                                            <p>
                                                                <a title="Contact us" href="/location-and-numbers/" className="ka_button small_button small_fire" style={{ opacity: '0.7' }}>
                                                                    <b className="read_learn_more_icon"></b><span>Contact Us</span>
                                                                </a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="module">
                                        <div>
                                            <div>
                                                <div>
                                                    <div className="custom">
                                                        <div className="one_fourth_last">
                                                            <h3>Get InTouch with us</h3>
                                                            <div id="mc_signup">
                                                                Get in touch with us.. we would like to hear from you. Send us your enquiries
                                                                at <a href="mailto:info@spmconsulting.net">info@spmconsulting.net</a> and we shall
                                                                reply back asap.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </Row>
                </Container>
            </Container>
            <Container fluid style={{ background: '#0A1322 url(../images/royal-blue/bg-footer.jpg) repeat-x' }}>
                <Container>
                    <Row>
                        <Col style={{ lineHeight: '40px' }}><p className="text-center text-white pt-3">Copyright © 2015 SPM Consulting. All rights reserved.</p></Col>
                    </Row>
                </Container>
            </Container>
        </>
    )
}

export default footer;