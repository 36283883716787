import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NavLink } from "react-router-dom";

function spmoutsourcingservices() {
    return (
        <>
            <Container>
                <div className="headerFrame">
                    <h1 style={{ color: 'white' }}>SPM Services</h1>
                </div>
                <p className="headerContent">
                    SPM Consulting not only provides solution implementation services, but also
                    business and framework advisory. The company indulges in extensive research
                    for the project it is working on. The end result being a more proficient business
                    solution and automating the existing processes, leading to superior decision making
                    by providing a better insight to data.
                    <br />
                    The company ensures an end-to-end comprehensive
                    implementation of SAS Solutions. SPM Consulting's added advantage of providing business
                    consultancy ensures perfect synchronization of the business framework with the
                    SAS solutions.
                    <br />
                    <b>Finance Industry Background:</b> The financial services industry, which
                    is highly unpredictable, sets the very foundation for an economy to operate on.
                    It is impulsive and volatile. Given these characteristics of the financial sector,
                    banks and other financial institutions have to structure themselves in a way that
                    they not only survive, but more importantly thrive in the ever changing and highly
                    competitive financial environment. Just when everyone felt that banks had it all
                    covered, the 2008 financial crisis struck the financial world. The crisis exposed
                    the weak structure of these “too big to fail” financial institutions. It was realized
                    that without dedicated efforts to improve the functionality and framework, the future
                    of these financial institutions would be bleak. That’s when the importance of comprehensive
                    business regulations such as the Basel II Accord came into the spotlight. The importance
                    of managing and curtailing not just credit and market risk, but also operational
                    risk, was globally understood. Banks realized that they cannot take the liberty
                    to overlook the importance of financial management, and have to be conspicuously
                    meticulous about it.
                    <br />
                    All these drastic developments have opened new doors to ardent
                    research and development towards a more secure and sound financial system. That’s
                    exactly what we do, here at SPM Consulting. With the advent of risk management solutions,
                    such as those offered by SAS Institute; the importance of business consultancy has
                    grown substantially. SPM Consulting has developed a fine-tuned and successful synergy
                    between innovative business strategies and enterprise solutions. Hence, we provide
                    consulting services not just at the technical level, but more importantly at the
                    organizational and strategic level ensuring continuity, integrity, transparency
                    and consistency in all the business processes.
                    <br />
                    SPM Consulting offers an array of solutions – ranging from Governance Risk Compliance
                    Solutions to Data Mining, Asset-Liability Management and Fraud Management.
                    The company believes in understanding the customer’s
                    needs and delivering value by following the best ethical practices. Our team has
                    pioneered in helping our clients combat increased vulnerability to risks and the
                    acute shortage of highly skilled personnel. Our team will work closely with the
                    clients to identify their requirements and provide meaningful solutions. The SPM
                    Consulting team has a skill set, which is diverse, yet exhaustive, in the field
                    of risk management and business solution.
                </p>
            </Container>
            <br />
            <br />
        </>
    )
}

export default spmoutsourcingservices;